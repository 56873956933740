<template>
  <div>
    <v-app-bar flat :clipped-left="$vuetify.breakpoint.lgAndUp" app class="grey lighten-5">
      <v-toolbar-title>
        <router-link to="/shop" class="black--text" style="text-decoration: none">Mr Tiggle B2B</router-link>
      </v-toolbar-title>

      <template v-if="!conditionsAccepted && this.$route.name == 'Conditions'">
        <v-spacer />

        <v-btn class="mr-2" to="/logout" icon>
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </template>

      <template v-if="isUserLogged && conditionsAccepted">
        <v-spacer />

        <v-text-field
          flat
          v-model="term"
          solo-inverted
          hide-details
          append-icon="mdi-magnify"
          label="Search"
          class="hidden-sm-and-down pl-10 ml-4"
          v-if="allowSearch"
          @keyup.enter="search"
          @click:append="search"
        />
        <v-chip v-if="searchTerm && allowSearch" class="ma-2" close @click:close="searchCancel">
          {{ searchTerm }}
        </v-chip>
        <v-spacer />
        <v-btn class="mr-2" to="/info" icon :disabled="this.$route.name === 'Info'">
          <v-icon>mdi-information</v-icon>
        </v-btn>
        <v-btn class="mr-2" to="/shop" icon :disabled="this.$route.name === 'Shop'">
          <v-icon>mdi-shopping</v-icon>
        </v-btn>
        <v-btn class="mr-2" to="/cart" icon :disabled="this.$route.name === 'Cart'">
          <v-badge :content="cartSize()" :value="cartSize()" color="green" overlap>
            <v-icon>mdi-cart</v-icon>
          </v-badge>
        </v-btn>

        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn class="mr-2" icon v-on="on">
              <v-icon>mdi-account-circle</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title>{{ email }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/orders">
              <v-list-item-title>{{ $t('orderHist') | capitalize }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/logout">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-app-bar>
    <template v-if="isUserLogged && displayMenuBar">
      <v-main>
        <v-bottom-navigation :value="activeBtn" color="primary" horizontal>
          <v-menu open-on-hover offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on">
                <span>{{ $t('products') | camelcase }}</span>
              </v-btn>
            </template>
            <v-card class="mx-auto" max-width="344" outlined>
              <v-list-item v-for="(item, index) in items" :key="index" to="/shop">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-card>
          </v-menu>
        </v-bottom-navigation>
      </v-main>
    </template>
    <Alert />
  </div>
</template>
<style>
  .v-toolbar__content {
    width: 100%;
    margin: 0 auto;
  }
  @media (min-width: 960px) {
    .v-toolbar__content {
      max-width: 1000px;
    }
  }
  @media (min-width: 1264px) {
    .v-toolbar__content {
      max-width: 1185px;
    }
  }
  @media (min-width: 1904px) {
    .v-toolbar__content {
      max-width: 1185px;
    }
  }
</style>
<script>
  import store from '@/store';
  import { isLoggedIn } from '@/utils/auth';
  import { sync } from 'vuex-pathify';

  export default {
    data: () => ({
      displayMenuBar: false,
      activeBtn: 1,
      term: '',
    }),
    computed: {
      searchTerm: sync('misc/searchTerm'),
      conditionsAccepted: sync('misc/proceed'),
      email() {
        return store.get('misc/auth').email;
      },
      isUserLogged() {
        return isLoggedIn() && this.$route.name != 'Login';
      },
      allowSearch() {
        return this.$route.name == 'Shop';
      },
    },
    watch: {
      searchTerm(value) {
        if (value.length === 0) {
          this.term = '';
        }
      },
    },
    methods: {
      cartSize() {
        return store
          .get('cart/lineItems')
          .filter((i) => i.user_id === store.get('misc/auth@id'))
          .reduce((n, { quantity }) => n + quantity, 0);
      },
      searchCancel() {
        store.set('misc/searchTerm', '');
        this.term = '';
      },
      search() {
        store.set('misc/searchTerm', this.term);
      },
    },
    components: {
      Alert: () => import('@/views/components/mini-components/Alert'),
    },
  };
</script>
